import { useEffect, useState, useRef } from "react";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { rotatePlugin } from "@react-pdf-viewer/rotate";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { connect } from "react-redux";
import * as pdfjsLib from "pdfjs-dist";
import { selectionModePlugin, SelectionMode } from "@react-pdf-viewer/selection-mode";
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import { fullScreenPlugin } from "@react-pdf-viewer/full-screen";

const PDF = ({ file, theme , zoomlevel }) => {
  const containerRef = useRef(null);
  const toolbarPluginInstance = toolbarPlugin();
  const { renderDefaultToolbar } = toolbarPluginInstance;
  // Initialize the default layout plugin
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar: (Toolbar) => (
      <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
    ),
  });
  const [isFullScreen, setIsFullScreen] = useState(false);

  const rotatePluginInstance = rotatePlugin();
  const { RotateBackwardButton, RotateForwardButton } = rotatePluginInstance;
  const [viewerKey, setViewerKey] = useState(0);
  const [scale, setScale] = useState(1.0);
  const selectionModePluginInstance = selectionModePlugin({
    selectionMode: SelectionMode.Hand, 
  });

  const transform = (slot) => ({
    ...slot,
    // These slots will be empty
    // SwitchSelectionMode: () => null,
    SwitchSelectionModeMenuItem: () => null
});

  const [selectedMode, setSelectedMode] = useState(SelectionMode.Hand);

  const handleModeChange = (mode) => {
    setSelectedMode(mode);
    selectionModePluginInstance.SwitchSelectionMode(mode);
  };

  const calculateScale = async (pdfUrl, containerWidth) => {
    try {
      const loadingTask = pdfjsLib.getDocument(pdfUrl);
      const pdf = await loadingTask.promise;
      const page = await pdf.getPage(1);
      const viewport = page.getViewport({ scale: 1.0 });
      const pageWidth = viewport.width;

      let calculatedScale = (containerWidth / pageWidth) * 0.95;

      setScale(calculatedScale);
    } catch (error) {
      console.error("Error getting page width:", error);
    }
  };

  const fullScreenPluginInstance = fullScreenPlugin({
    onEnterFullScreen: () => {
      setIsFullScreen(true);
      containerRef.current = null;
    },
    onExitFullScreen: () => {
      setIsFullScreen(false);
    },
  });

  useEffect(() => {
    if (isFullScreen) {
        return;
    }
    if (file && containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      calculateScale(file, containerWidth);

      const resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          if (entry.target === containerRef.current) {
            calculateScale(file, entry.contentRect.width);
          }
        }
      });

      resizeObserver.observe(containerRef.current);

      const currentContainer = containerRef.current;
      return () => {
        if (resizeObserver && currentContainer) {
          resizeObserver.unobserve(currentContainer);
        }
      };
    }
}, [file, isFullScreen]);

  useEffect(() => {
    setViewerKey((prevKey) => prevKey + 1);
  }, [scale]);

  return (
    <div
      ref={containerRef}
      className={`pdf ${theme === "dark" ? "pdf-dark" : ""}`}
    >
      {file && (
        // <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js">
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.3.122/build/pdf.worker.min.js">
          <div className="btn-rotate" style={{ left:"200px", width:"70px" }} >
            <div
              style={{
                borderRadius: "4px",
                backgroundColor:
                  selectedMode === SelectionMode.Hand
                    ? theme === "dark"
                      ? "#121212"
                      : "#d3d3d3"
                    : "transparent",
              }}
              onClick={() => handleModeChange(SelectionMode.Hand)}
            >
              {selectionModePluginInstance.SwitchSelectionModeButton({
                mode: SelectionMode.Hand,
              })}
            </div>
            <div
              style={{
                borderRadius: "4px",
                backgroundColor:
                  selectedMode === SelectionMode.Text
                    ? theme === "dark"
                      ? "#121212"
                      : "#d3d3d3"
                    : "transparent",
              }}
              onClick={() => handleModeChange(SelectionMode.Text)}
            >
              {selectionModePluginInstance.SwitchSelectionModeButton({
                mode: SelectionMode.Text,
              })}
            </div>
          </div>
          <div className="btn-rotate">
            <div
              style={{ padding: "0px 2px" }}
              className={`rotate-btn ${
                theme === "dark" ? "btn-dark" : "btn-light"
              }`}
            >
              <RotateBackwardButton />
            </div>
            <div
              style={{ padding: "0px 2px" }}
              className={`rotate-btn ${
                theme === "dark" ? "btn-dark" : "btn-light"
              }`}
            >
              <RotateForwardButton />
            </div>
          </div>
          <Viewer
            key={viewerKey}
            className="viewer"
            fileUrl={file}
            plugins={[defaultLayoutPluginInstance, rotatePluginInstance, selectionModePluginInstance, fullScreenPluginInstance]}
            defaultScale={zoomlevel ?? scale}
            theme={theme === "dark" ? "dark" : "light"}
          />
        </Worker>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  theme: state.user.theme,
});

export default connect(mapStateToProps)(PDF);
