import Modal from "react-bootstrap/Modal";
import PDF from "../pdf";
import PdfComment from "../pdfComment";
import ReportModal from "../reportModal";
import InstrumentEditContainer from "../instrumentEdit";
import { useState } from "react";
import { MdEditNote, MdClose } from "react-icons/md";
import { connect } from "react-redux";

const PdfModal = ({ selectedPdf, showModal, onHide, errorData = null, type = '',theme }) => {
  const [showComments, setShowComments] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const title = "County: " + selectedPdf?.instrument?.co + 
  (type === "Error" 
    ? (" | Instrument: " + selectedPdf?.instrument?.iid) 
    : (selectedPdf?.instrument?.bn && selectedPdf?.instrument?.pn 
        ? (" | Book/Page: " + selectedPdf?.instrument?.bn + " / " + selectedPdf?.instrument?.pn) 
        : "")
  );
  
  return (
    <Modal 
      className={`modal${theme === 'dark' ? '-dark' : ''}`}
      show={showModal}
      onHide={onHide}
      style={{ marginTop: "3%" }}
      size="xl"
      animation={false}
    >
      <Modal.Header>
        <Modal.Title>
          {title}
        </Modal.Title>
        {!showReportModal && type !== 'Error' && (<button
          className="btn btn-error report-btn"
          onClick={() => {
          setShowReportModal(true);
        }}> Report Issue! </button>)}
        <button className="custom-close-button" onClick={onHide}>
          <MdClose className="cross-icon" />
        </button>
      </Modal.Header>
      <Modal.Body style={{ height: "81vh" }}>
        <>
          {selectedPdf && (
            <div className={`pdf-modal ${(showComments || showReportModal || (type === 'Error' && errorData)) ? "pdf-container" : ""} ${theme === 'dark' ? 'pdf-false-dark' : ''}`}>
              {selectedPdf.pdf && !showComments && !showReportModal && type !== 'Error' && (
                <MdEditNote
                  className="floater-btn btn-icon"
                  onClick={() => setShowComments(true)}
                />
              )}
              {selectedPdf.pdf ? (
                <PDF file={selectedPdf.pdf} />
              ) : (
                <div className="downloading-center">Loading Document...</div>
              )}
              {showComments && (
                <PdfComment
                  onCancel={() => setShowComments(false)}
                  instrument={selectedPdf?.instrument}
                />
              )}
              {showReportModal && (
                <ReportModal 
                  onCancel={() => setShowReportModal(false)}
                  instrument={selectedPdf?.instrument}
                />
              )}
              {type === 'Error' && errorData && (
                <InstrumentEditContainer errorData={errorData} onCancel={onHide}/>
              )}
            </div>
          )}
        </>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  theme: state.user.theme,
});

export default connect(mapStateToProps)(PdfModal);
