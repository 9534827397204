import { useState } from "react";
import { connect } from "react-redux";

import { reportIssue } from "../../../actions/advancedSearchAction";

const ReportModal = ({ onCancel, theme, instrument, reportIssue, reportReasons }) => {
  const [comment, setComment] = useState("");
  const [reasonChecks, setReasonChecks] = useState({
    wbp: "0",
    wgg: "0",
    wl: "0",
    wit: "0",
    wd: "0",
    mp: "0",
    poo: "0",
    others: "0",
  });

  const handleReasonCheck = (e) => {
    if (e?.target?.name) {
      setReasonChecks({
        ...reasonChecks,
        [e.target.name]: e.target.checked ? "1" : "0",
      });
    }
  };

  return (
    <div className={`edit-instrument${theme === "dark" ? "-dark" : ""}`}>
      <div style={{fontSize: '15px', color: theme === "dark" ? "#F5F8FA" : "", marginBottom: '10px', fontWeight: '600', textAlign: 'center'}}>
          Kindly provide a detailed description of the issue you are
          encountering with this instrument.
      </div>
      <div className="content">
        {reportReasons.map((reason) => {
          return (
            <div className="check-box separateGG" key={reason.id} style={{border: "1px solid #e4e4e4", borderRadius: "5px", display: "flex", alignItems: "center", marginLeft: "0px", padding: "5px"}}>
              <input
                className="check-pointer"
                type="checkbox"
                name={reason.id}
                checked={reasonChecks[reason.id] === "1"}
                onChange={handleReasonCheck}
                style={{margin: "0px", height: "20px", width: "20px"}}
              />
              <label style={{margin: "0px 20px"}}>{reason.name}</label>
            </div>
          );
        })}
      </div>
      <div className="d-flex flex-column buttons" style={{marginRight:"0px"}}>
        <div style={{ marginLeft:"10px" }}>
          <textarea
            placeholder="Add your comment here"
            className="form-control bg-transparent"
            value={comment}
            maxLength={1000}
            style={{height: '20vh'}}
            onChange={(e) => setComment(e.target.value)}
          />
        </div>
        <div
          style={{ display: "flex", marginTop: "10px", justifyContent: "space-between"}}
        >
          <button
            className="btn btn-primary"
            style={{width: "100%"}}
            onClick={() => {
              const reasons = reportReasons
              .filter((reason) => reasonChecks[reason.id] === "1")
              .map((reason) => reason.name)
              .join(", ");
              const requestedDate = instrument.requestedDate;
              const formattedDate = `${requestedDate.slice(0, 4)}-${requestedDate.slice(4, 6)}-${requestedDate.slice(6, 8)}`
              reportIssue({
                ed: reasons,
                cmt: comment,
                iid: instrument.iid,
                co: instrument.co,
                rd: formattedDate
              });
              setTimeout(() => {
                onCancel();
              }, 1000);
            }}
          >
            Report
          </button>
          <button className="btn btn-info" style={{width: "100%"}} onClick={() => onCancel()}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  theme: state.user.theme,
  un: state.user.username,
  reportReasons: state.userLog.reportReasons,
});

const mapDispatchToProps = (dispatch) => ({
  reportIssue: (data) => dispatch(reportIssue(data)),
});

const ReportModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportModal);

export default ReportModalContainer;