import { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";

import {
    addPdfComments
} from "../../../actions/advancedSearchAction";

const PdfComment = ({ onCancel, theme, pdfComments, un, addPdfComments, instrument }) => {
    const chatRef = useRef(null);
    const [comment, setComment] = useState('');

    useEffect(() => {
        const divElement = chatRef.current;
        divElement.scrollTop = divElement.scrollHeight;
    }, [pdfComments, chatRef]);

    return (
        <div className={`edit-instrument${theme === "dark" ? "-dark" : ""}`}>
        <div ref={chatRef} className="content">
            {pdfComments.map((comment, i) => {
            return (
                <div
                key={i}
                className={`chat ${comment.username === un && "self"}`}
                >
                <div className="author">{comment.username}</div>
                <div className="message">{comment.cmt}</div>
                <div className="time">{comment.rt.toLocaleString("en-US")}</div>
                </div>
            );
            })}
        </div>
        <div className="d-flex flex-column buttons" style={{marginRight:"0px"}}>
            <div style={{ marginLeft:"10px"}}>
                <textarea
                    placeholder="Add your comment here"
                    className="form-control bg-transparent"
                    value={comment}
                    maxLength={1000}
                    onChange={(e) => setComment(e.target.value)}            
                />
            </div>
            <div style={{display: 'flex', marginTop: '10px',  justifyContent: "space-between"}}>
                <button
                className="btn btn-primary"
                style={{width: "100%"}}
                onClick={() => {
                    addPdfComments({
                        "co" : instrument.co,
                        "iid" : instrument.iid,
                        "cmt" : comment,
                        "username": un
                    });
                    setComment('');
                }}
                >
                Add
                </button>
                <button className="btn btn-info" style={{width: "100%"}} onClick={() => onCancel()}>
                    Close Thread
                </button>
            </div>
        </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
  theme: state.user.theme,
  pdfComments: state.advancedSearch.pdfComments,
  un: state.user.username,
});

const mapDispatchToProps = (dispatch) => ({
    addPdfComments: (data) => dispatch(addPdfComments(data)),
});

const PdfCommentContainer = connect(mapStateToProps, mapDispatchToProps)(PdfComment);

export default PdfCommentContainer;