import { connect } from "react-redux";
import { useState, useCallback, useEffect, useRef, useMemo } from "react";
import NavBarContainer from "../../components/common/navbar";
import Table from "../../components/common/table";
import SearchFilter from "../../components/searchFilter";
// import ShowMoreButtonNewContainer from "../../components/common/moreButton/moreButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCompressArrowsAlt,
  faExpandArrowsAlt,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "../../components/common/modal";
import MapgroundNew from "../../components/common/MapgroundNew";
import { WithContext as ReactTags } from "react-tag-input";
import PdfModal from "../../components/common/pdfModal";
import ExcelJS from "exceljs";
import moment from "moment";
import ConfirmationModal from "../../components/common/confirmationModal";
import {
  getInstrumentListNew,
  getInstrumentPdf,
  downloadInstrumentPdf,
  removeTag,
  getPdfComments,
  addFilterInPrevious,
} from "../../actions/advancedSearchAction";

import { getOkcrAccounts } from "../../actions/okcrAction";
import { parseTRSMString } from "../../utils";
import { getInstruments, resetTrs } from "../../actions/instrumentAction";
import Split from "react-split";
import { Tab, Tabs } from "react-bootstrap";
import { dailyReportTabs } from "../dailyReport/tabDetails";
import PdfPopUp from "../../components/common/pdfPopUp";
import { getDocument } from "../../actions/dailyReport";
import { TRSRenderer, GrGeRenderer } from "./renderers";

function AdvanceSearchNew({
  theme,
  instrumentList,
  getInstrumentListNew,
  getInstrumentPdf,
  getOkcrAccounts,
  downloadInstrumentPdf,
  loading,
  getInstruments,
  geojson,
  tags,
  removeTag,
  selectedPdf,
  getPdfComments,
  colorMapping,
  resetTrs,
  regulatoryAppsOrders,
  getDocument,
  selectedAppsTabPdf,
  ordersLoading,
  addFilterInPrevious,
  filterData,
  trsm,
  wellData,
  wellLoading
}) {
  const div1Ref = useRef();
  const defaultSize = [53, 47];
  const [splitSizes, setSplitSizes] = useState(defaultSize);
  const [modalBody, setModalBody] = useState("");
  const [modalHeader, setModalHeader] = useState("");
  const [modalFooter, setModalFooter] = useState("");
  const [show, setShow] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showPdf, setShowPdf] = useState(false);
  const [mapHeight, setMapHeight] = useState("300");
  const [isExpandTable, setIsExpandTable] = useState(false);
  const [trs, setTrs] = useState(null);
  const [clickedIds, setClickedIds] = useState([]);
  const [showPopPdf, setShowPopPdf] = useState(false);
  const [activeTab, setActiveTab] = useState("Land");
  const [expandedRows, setExpandedRows] = useState({});
  const [controller, setController] = useState();
  const [parsedEventData, setParsedEventData] = useState(null);
  const stableLoading = useMemo(() => ordersLoading, [ordersLoading]);
  const memoizedHeight = useMemo(() => 42, []);
  const memoizedColumnDef = useMemo(
    () => ({
      wrapText: true,
      autoHeight: true,
    }),
    []
  ); 

  const memoizedRegulatoryAppsOrdersMapped = useMemo(
    () =>
      regulatoryAppsOrders.map((item) => ({
        ...item,
        dr: new Date(item.dr),
        vw: item.vw, // Keep vw as is
      })),
    [regulatoryAppsOrders]
  );

  useEffect(() => {
    const storedClickedIds = JSON.parse(localStorage.getItem("clickedIds")) || [];
    setClickedIds(storedClickedIds);
  }, []);

  const handleClick = (iid) => {
    let updatedClickedIds;
    if (clickedIds.includes(iid)) {
      updatedClickedIds = clickedIds.filter((id) => id !== iid);
    } else {
      updatedClickedIds = [...clickedIds, iid];
    }
    setClickedIds(updatedClickedIds);
    localStorage.setItem("clickedIds", JSON.stringify(updatedClickedIds)); 
  };

  const showConfirmationBox = (trs) => {
    setTrs(trs);
    setShowConfirmationModal(true);
  };

  const getInst = () => {
    getInstruments(trs.trim());
  };

  const showTagError = () => {
    setModalHeader("");
    setModalBody("This filter cannot be removed");
    setModalFooter(
      <div>
        <button className="btn btn-info" onClick={() => setShow(false)}>
          Cancel
        </button>
      </div>
    );
    setShow(true);
  };

  const fetchInstrumentList = useCallback(
    () => {
      const data = {
        page: filterData.page,
        book: filterData.book,
        from_date: filterData.from_date,
        to_date: filterData.to_date,
        trs: trsm.length ? trsm.join(",") : (filterData.trsm ? filterData.trsm.join(",") : ""),
        trs_array: JSON.stringify(parseTRSMString(trsm.join(","))),
        county: filterData.county.join(","),
        instrument_type: filterData.instrument_type.join(","),
        grantees: filterData.isGGSeparate
          ? filterData.grantees.join(", ")
          : filterData.ggTogether.join(", "),
        grantors: filterData.isGGSeparate
          ? filterData.grantors.join(", ")
          : filterData.ggTogether.join(", "),
        gg_condition: filterData.isGGSeparate ? "AND" : "OR",
        isDateFiltered: filterData.isDateFiltered,
      };
      data['search_data'] = JSON.stringify(data);
      addFilterInPrevious(data['search_data']);
      const newController = new AbortController();
      setController(newController);
      getInstrumentListNew(data, newController.signal)
    },
    [getInstrumentListNew, addFilterInPrevious, filterData, trsm]
  );

  const abortController = () => {
    if(controller) controller.abort();
  }

  const openInstrument = (instrument, hd) => {
    setShowPdf(true);
    if (hd) getInstrumentPdf(instrument);
    else downloadInstrumentPdf(instrument);
  };

  const toggleRowExpansion = (rowId) => {
    setExpandedRows((prev) => ({
      ...prev,
      [rowId]: !prev[rowId],
    }));
  };

  const cols = [
    {
      headerName: "Instrument",
      field: "iid",
      width: 110,
      wrapHeaderText: true,
      cellRenderer: ({ data }) => {
        let content = '';
        if(data.hd) {
          content = (
            // eslint-disable-next-line
            <a
              className={`check-pointer ${
                clickedIds.includes(data.iid) ? "clicked" : ""
              }`}
              style={{
                cursor: "pointer",
                color: clickedIds.includes(data.iid) ? "purple" : "",
              }}
              onClick={() => {
                handleClick(data.iid);
                let requestedDate = null; // Initialize requestedDate
                if (data.dr instanceof Date) {
                  const year = data.dr.getUTCFullYear();
                  const month = String(data.dr.getUTCMonth() + 1).padStart(
                    2,
                    "0"
                  ); // Month is zero-based
                  const day = String(data.dr.getUTCDate()).padStart(2, "0");
                  requestedDate = `${year}${month}${day}`; // Set requestedDate in UTC
                }
                const instrumentWithDate = { ...data, requestedDate };

                openInstrument(instrumentWithDate, true); // Pass the modified instrument

                let requestRecord = {
                  co: data.co,
                  iid: data.iid,
                  recordeddate: requestedDate
                };
                // Fetch PDF comments
                getPdfComments(requestRecord);
              }}
            >
              View
            </a>
          );
        } else {
          if(data.co === "CANADIAN" || data.co === "CADDO" || data.co === "TULSA" || data.co === "OKLAHOMA") {
            content = (<span style={{color: 'gray'}}>Unavailable</span>);
          } else {
            content = 
            // eslint-disable-next-line
            (<a
              href="#"
              onClick={() => {
                openInstrument(data, false);
              }}
            >
              Download
            </a>);
          }
        }
        return content;
      }
    },
    {
      headerName: "Instrument Type",
      field: "it",
      width: 150,
      wrapHeaderText: true,
    },
    {
      headerName: "Instrument Type Normalize",
      field: "itn",
      width: 140,
      wrapHeaderText: true,
    },
    {
      headerName: "County",
      field: "co",
      width: 100,
      wrapHeaderText: true,
    },
    {
      headerName: "TRS",
      field: "trs",
      cellRenderer: TRSRenderer,
      wrapText: true, autoHeight: true,
      cellRendererParams: {
        expandedRows,
      toggleRowExpansion,
        showConfirmationBox: showConfirmationBox,
      },
      // cellRendererParams: {
      //   field: "trs",
      //   length: 1,
      //   isAdvanced: true,
      //   isArray: true,
      //   isHyperlink: true,
      //   strLength: 10,
      //   callback: showConfirmationBox,
      // },
      width: 160,
      wrapHeaderText: true,
      // cellStyle: (params) => {
      //   if (params.data.expand) {
      //     return {
      //       lineHeight: '1.5',
      //       whiteSpace: 'normal',
      //        paddingTop: '8px'
      //     };
      //   }
      //   return null;
      // },
    },
    { headerName: "Book", field: "bn", width: 80 },
    { headerName: "Page", field: "pn", width: 110 },
    {
      headerName: "Total Consideration",
      field: "ct",
      width: 130,
      wrapHeaderText: true,
      valueFormatter: ({ value }) => {
        value = parseFloat(value);
        if (isNaN(value)) {
          return "";
        } else {
          return value.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          });
        }
      },
    },
    {
      headerName: "Recorded Date",
      field: "dr",
      width: 110,
      wrapHeaderText: true,
      filter: "agDateColumnFilter",
      cellRenderer: ({ value }) =>
        value instanceof Date
          ? value.toLocaleDateString("en-US", { timeZone: "UTC" })
          : "",
    },
    {
      headerName: "Grantors",
      field: "gr",
      wrapText: true, 
      autoHeight: true,
      cellRenderer: GrGeRenderer,
      cellRendererParams: {
        expandedRows, // Pass the shared expandedRows state
        toggleRowExpansion, // Pass the function to toggle row expansion
      },
      // cellRendererParams: {
      //   field: "gr",
      //   length: 1,
      //   isAdvanced: true,
      //   isArray: true,
      //   strLength: 32,
      // },
      flex: 1,
      wrapHeaderText: true,
    },
    {
      headerName: "Grantees",
      field: "ge",
      wrapText: true,
      autoHeight: true,
      cellRenderer: GrGeRenderer,
      cellRendererParams: {
        expandedRows, // Pass the shared expandedRows state
        toggleRowExpansion, // Pass the function to toggle row expansion
      },
      // cellRendererParams: {
      //   field: "ge",
      //   length: 1,
      //   isAdvanced: true,
      //   isArray: true,
      //   strLength: 32,
      // },
      flex: 1,
      wrapHeaderText: true,
    },
    { headerName: "Rank", field: "rnk", maxWidth: 80 },
    // {
    //   headerName: "",
    //   resizable: false,
    //   filter: false,
    //   maxWidth: 55,
    //   cellRenderer: ({ data }) =>
    //     (data.hd || data.co === "CANADIAN" || data.co === "CADDO") ? (
    //       ""
    //     ) : (
    //       <MdArrowCircleDown
    //         className={`btn-icon ${theme === "dark" ? "btn-light" : ""}`}
    //         onClick={() => {
    //           openInstrument(data, false);
    //         }}
    //       />
    //     ),
    // },
  ];

  useEffect(() => {
    // reset the trs value on page load, so redirect to dashboard does not have pre loaded trs value
    resetTrs();
    let resizeTimeout = null;
    const observer = new ResizeObserver((entries) => {
      clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(() => {
        for (const entry of entries) {
          const { height } = entry.contentRect;
          setMapHeight(height - 10);
        }
      }, 500);
    });

    const divElement = div1Ref.current;
    if (divElement) {
      observer.observe(divElement);
    }

    return () => {
      if (divElement) {
        observer.unobserve(divElement);
      }
    };
  }, [resetTrs]);
  const handleDelete = (index) => {
    const tag = tags[index];
    if (["from_date", "to_date"].includes(tag.id)) {
      showTagError();
    } else {
      removeTag(index);
      fetchInstrumentList();
    }
  };

  const downloadExcel = async () => {
    const data = [...instrumentList];
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet1");
    // Set column widths
    worksheet.columns = [
      { header: "Instrument Number", key: "iid", width: 20 },
      { header: "Instrument Type", key: "it", width: 20 },
      { header: "Instrument Type Normalize", key: "itn", width: 30 },
      { header: "County", key: "co", width: 10 },
      { header: "TRS", key: "trs", width: 20 },
      { header: "Book", key: "bn", width: 10 },
      { header: "Page", key: "pn", width: 10 },
      { header: "Total Consideration", key: "ct", width: 20 },
      { header: "Recorded Date", key: "dr", width: 20 },
      { header: "Grantors", key: "gr", width: 30 },
      { header: "Grantees", key: "ge", width: 30 },
    ];

    // Add data to the worksheet
    data.forEach((item) => {
      worksheet.addRow(item);
    });

    // Enable text wrapping
    worksheet.eachRow({ includeEmpty: true }, (row) => {
      row.eachCell((cell) => {
        cell.alignment = { wrapText: true };
      });
    });

    // Write to a file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `advance_search_(${moment().format("YYYY-MM-DD")}).xlsx`;
    a.click();
    URL.revokeObjectURL(url);
  };

  useEffect(() => {
    getOkcrAccounts();
  }, [getOkcrAccounts]);

  const memoizedColsReg = useMemo(() => [
    {
      headerName: "View",
      field: "vw",
      wrapHeaderText: true,
      width: 80,
      cellRenderer: (params) => {
        const { data } = params;
        const uniqueId = data.vw;
        const clickedIds =
          JSON.parse(localStorage.getItem("clickedRowNodeIds")) || [];
        const isClicked = clickedIds.includes(uniqueId);
  
        return (
          <span
            className="check-pointer"
            style={{ color: isClicked ? "purple" : "#009EF7" }}
            onClick={(event) => {
              if (!clickedIds.includes(uniqueId)) {
                const updatedClickedIds = [...clickedIds, uniqueId];
                localStorage.setItem(
                  "clickedRowNodeIds",
                  JSON.stringify(updatedClickedIds)
                );
                event.target.style.color = "purple";
              }
              setShowPopPdf(true);
              getDocument({ tab: "regulatory", eid: data.vw });
            }}
          >
            View
          </span>
        );
      },
    },
    ...dailyReportTabs
      .find((tab) => tab.key === "regulatory")
      .cols.filter((col) => !["View", "Action"].includes(col.headerName)),
      // eslint-disable-next-line 
  ], [dailyReportTabs]);  // 

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const eventData = params.get("eventData");
    if (eventData) {
      const parsedEventData = JSON.parse(decodeURIComponent(eventData));
      setParsedEventData(parsedEventData);
    }
  }, []);

  return (
    <div
      className={`dashboard${
        theme === "dark" ? "-dark" : ""
      } d-flex flex-column`}
    >
      <NavBarContainer />
      <SearchFilter getFilteredData={fetchInstrumentList} abortController={abortController} parsedEventData={parsedEventData}/>
      <Split
        sizes={splitSizes}
        minSize={0}
        className="split"
        direction="vertical"
      >
        <div ref={div1Ref}>
          <MapgroundNew
            theme={theme}
            geojson={geojson ?? []}
            height={mapHeight}
            setMapHeight={setSplitSizes}
            colorMapping={colorMapping}
            activeTab={activeTab}
            regulatoryGeojson={regulatoryAppsOrders}
          />
        </div>
        <div className="content d-flex flex-column">
          <div className="data d-flex flex-column">
            <div className="d-flex  align-items-center info-box">
              <div className="d-flex info-box">
                {/* <div>
                <span className="section mb-1">
                {loading ? "Fetching..." : `${instrumentList.length} records `}
              </span>
                  {instrumentList.length >= 10000 ? (
                    <span
                      style={{
                        position: "absolute",
                        top: "33px",
                        left: "25px",
                      }}
                    >
                      (Max Records)
                    </span>
                  ) : (
                    ""
                  )}
                </div> */}
                <div className="tags">
                  <ReactTags
                    tags={tags}
                    handleDelete={handleDelete}
                    allowDragDrop={false}
                  />
                </div>
              </div>
              {!loading && (
                <FontAwesomeIcon
                  className="btn icon btn-download"
                  icon={faDownload}
                  onClick={downloadExcel}
                />
              )}
              <FontAwesomeIcon
                className="btn icon"
                icon={isExpandTable ? faCompressArrowsAlt : faExpandArrowsAlt}
                onClick={() => {
                  if (isExpandTable) {
                    setSplitSizes(defaultSize);
                  } else {
                    setSplitSizes([0, 100]);
                  }
                  setIsExpandTable(!isExpandTable);
                }}
              />
            </div>

            {/* Tabs component */}
            <Tabs
              activeKey={activeTab}
              onSelect={(k) => setActiveTab(k)}
              id="data-tabs"
            >
              <Tab eventKey="Land" 
                title={`Land (${loading ? "Fetching..." : instrumentList.length.toLocaleString()})`}
              >
                <div className="d-flex flex-column">
                  <div className="digital split-table d-flex flex-column">
                    <Table
                      cols={cols}
                      data={instrumentList}
                      showExport={true}
                      loading={loading}
                      tableId= "Land"
                    />
                  </div>
                </div>
              </Tab>
              <Tab eventKey="regulatory"
                title={`Apps/Orders (${ordersLoading ? "Fetching..." : regulatoryAppsOrders.length.toLocaleString()})`}
                className="Apps-Orders"
              >
                <Table
                  cols={memoizedColsReg}
                  data={memoizedRegulatoryAppsOrdersMapped}
                    columnDef={memoizedColumnDef}
                    height={memoizedHeight}
                    loading={stableLoading}
                    tableId= "regulatory"
                  />
              </Tab>
              {/* <Tab eventKey="Well"
                title={`Well (${wellLoading ? "Fetching..." : wellData.length.toLocaleString()})`}
                className="Well"
              >
                <Table
                  cols={wellCols}
                  data={wellData}
                  loading={wellLoading}
                  columnDef={{
                    cellStyle: {
                      display: 'flex',
                      alignItems: 'center',
                      lineHeight: '1.5',
                      whiteSpace: 'nowrap',
                    },
                  }}
                  />
              </Tab> */}
            </Tabs>
          </div>
        </div>
      </Split>

      <Modal
        showModal={show}
        header={modalHeader}
        html={modalBody}
        footerHtml={modalFooter}
        onHide={() => setShow(false)}
      />
      <ConfirmationModal
        showModal={showConfirmationModal}
        onHide={() => setShowConfirmationModal(false)}
        getInstruments={getInst}
      />
      <PdfModal
        selectedPdf={selectedPdf}
        showModal={showPdf}
        onHide={() => setShowPdf(false)}
      ></PdfModal>
      <PdfPopUp
        selectedPdf={selectedAppsTabPdf}
        showModal={showPopPdf}
        onHide={() => setShowPopPdf(false)}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    theme: state.user.theme,
    instrumentList: state.advancedSearch.instrumentList,
    geojson: state.advancedSearch.geojson,
    loading: state.advancedSearch.loading,
    tags: state.advancedSearch.tags,
    selectedPdf: state.advancedSearch.selectedPdf,
    colorMapping: state.advancedSearch.colorMapping,
    regulatoryAppsOrders: state.advancedSearch.regulatoryAppsOrders,
    selectedAppsTabPdf: state.dailyReport.pdf,
    ordersLoading: state.advancedSearch.ordersLoading,
    filterData: state.advancedSearch.filterData,
    trsm: state.advancedSearch.trsm,
    wellData: state.advancedSearch.wellData,
    wellLoading: state.advancedSearch.wellLoading
  };
};

const mapDispatchToProps = (dispatch) => ({
  getInstrumentListNew: (filters, signal) => dispatch(getInstrumentListNew(filters, signal)),
  getInstrumentPdf: (instrument) => dispatch(getInstrumentPdf(instrument)),
  downloadInstrumentPdf: (instrument) =>
  dispatch(downloadInstrumentPdf(instrument)),
  getInstruments: (trs) => dispatch(getInstruments(trs)),
  removeTag: (tagIndex) => dispatch(removeTag(tagIndex)),
  getPdfComments: (data) => dispatch(getPdfComments(data)),
  getOkcrAccounts: (data) => dispatch(getOkcrAccounts(data)),
  resetTrs: () => dispatch(resetTrs()),
  getDocument: (payload) => dispatch(getDocument(payload)),
  addFilterInPrevious: (filters) => dispatch(addFilterInPrevious(filters)),
});

const AdvanceSearchNewContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdvanceSearchNew);

export default AdvanceSearchNewContainer;