/**
 * Auth Sagas
 */
import { all, takeEvery, call, put, race, take } from "redux-saga/effects";
import * as types from "../actions/types";
import { getStore } from "../store";

import * as api from "../apis/userAuth";
import { toast } from "react-toastify";
import history from "../customRoutes/history";

let isRefreshing = false;
let refreshQueue = [];

// Login user
export function* loginUserSaga({ payload: { email, password } }) {
  try {
    const resData = yield call(api.doLogin, { username: email, password });
    if (resData.accessToken) {
      yield put({
        type: types.LOGIN_USER_SUCCESS,
        payload: {
          accessToken: resData.accessToken,
          refreshToken: resData.refreshToken,
        },
      });
      yield put({
        type: types.PROFILE_REQUEST,
      });
    } else {
      yield put({
        type: types.LOGIN_USER_FAILURE,
        payload: { error: resData.message },
      });
    }
  } catch (err) {
    if (err.response?.data) {
      const errorMessage = err.response.data.message === "Auth failed" 
        ? "Username or Password is incorrect" 
        : err.response.data.message;
  
      yield put({
        type: types.LOGIN_USER_FAILURE,
        payload: { error: errorMessage },
      });
  
      toast.error(errorMessage);
    }
     else {
      yield put({
        type: types.LOGIN_USER_FAILURE,
        payload: { error: err.message },
      });
      toast.error(err.message || "Error occured, please try again later.");
    }
  }
}

// User profile
export function* profileSaga() {
  try {
    const resData = yield call(api.doProfile);
    if (resData[0].error)
      yield put({
        type: types.PROFILE_FAILURE,
        payload: { err: resData[0].message },
      });
    else
      yield put({
        type: types.PROFILE_SUCCESS,
        payload: resData[0],
      });
    // window.location.reload(true);
  } catch (err) {
    yield put({
      type: types.PROFILE_FAILURE,
      payload: { err },
    });
    toast.error(err.message || "Error occured, please try again later.");
  }
}

// Signup user
export function* signupSaga({ payload: { userDetails, token } }) {
  try {
    const resData = yield call(api.doSignup, { userDetails, token });
    if (resData[0].success) {
      yield put({
        type: types.SIGNUP_SUCCESS,
        payload: {},
      });
      toast.success(resData[0].success);
      history.push("/login");
    } else {
      yield put({
        type: types.SIGNUP_FAILURE,
        payload: { error: resData[0].error },
      });
      toast.error(resData[0].error);
    }
  } catch (err) {
    if (err.response?.data) {
      yield put({
        type: types.SIGNUP_FAILURE,
        payload: { error: err.response.data.error },
      });
      toast.error(err.response.data.error);
    } else {
      yield put({
        type: types.SIGNUP_FAILURE,
        payload: { error: err.response.data.error },
      });
      toast.error(err.message || "Error occured, please try again later.");
    }
  }
}

// Logout user
export function* logoutUserSaga({ payload: { email } }) {
  try {
    yield call(api.doLogout, { username: email });
  } catch (err) {
  } finally {
    yield put({
      type: types.CLEAR_ALL_DATA,
      payload: {},
    });
    // refresh on logout
    window.location.reload(true);
  }
}

//------------------------- referesh token ---------------------------

export function* refreshTokenSaga() {
  try {
    // eslint-disable-next-line no-shadow
    const { refreshToken } = getStore().getState().user;
    const { accessToken } = yield call(api.doRefreshToken, refreshToken);

    yield all([
      yield put({
        type: types.REFRESH_TOKEN_SUCCESS,
        payload: { accessToken },
      }),
    ]);
  } catch (error) {
    yield all([yield put({ type: types.RESET_APP_REQUEST, payload: {} })]);
    yield all([yield put({ type: types.LOGOUT_USER_REQUEST, payload: {} })]);
  }
}

const ignoreActionTypes = [
  "REFRESH_TOKEN_REQUEST",
  "LOGIN_USER_REQUEST",
  "LOGOUT_USER_REQUEST",
];

const monitorableAction = (action) =>
  action.type.includes("REQUEST") &&
  ignoreActionTypes.every((fragment) => !action.type.includes(fragment));

const identifyAction = (action) =>
  action.type.split("_").slice(0, -1).join("_");

function* monitor(action) {
  yield put({ type: types.LOADING_START });
  const { fail } = yield race({
    success: take(`${identifyAction(action)}_SUCCESS`),
    fail: take(`${identifyAction(action)}_FAILURE`),
  });

  if (fail?.payload) {
    const err = fail.payload.err;
    if (err?.response?.status === 401) {
      if (!isRefreshing) {
        isRefreshing = true;
        yield put({ type: types.REFRESH_TOKEN_REQUEST });

        const { success } = yield race({
          success: take(types.REFRESH_TOKEN_SUCCESS),
          fail: take(types.REFRESH_TOKEN_FAILURE),
        });

        if (success) {
          for (const queuedAction of refreshQueue) {
            yield put(queuedAction);
          }
          refreshQueue = [];
        } else {
          toast.error("Session expired, please log in again.");
        }
        isRefreshing = false;
      } else {
        refreshQueue.push(action);
      }
    } else {
      toast.error(err.message || "Error occurred, please try again later.");
    }
  }
  yield put({ type: types.LOADING_FINISH });
}

// User Saga
export default function* userSaga() {
  yield all([
    takeEvery(monitorableAction, monitor),
    takeEvery(types.REFRESH_TOKEN_REQUEST, refreshTokenSaga),
    takeEvery(types.LOGIN_USER_REQUEST, loginUserSaga),
    takeEvery(types.PROFILE_REQUEST, profileSaga),
    takeEvery(types.SIGNUP_REQUEST, signupSaga),
    takeEvery(types.LOGOUT_USER_REQUEST, logoutUserSaga),
  ]);
}