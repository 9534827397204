import SelectViewNew from "./SelectViewNew";
import TagsInput from "react-tagsinput";
import { connect } from "react-redux";
import "./viewFilter.scss"

const SearchFilterView = ({ filterData, theme, type }) => {
  return (
    <div className="search-filter-view">
      <div className="d-flex">
        {filterData.isGGSeparate && (
          <>
            <div className="grantees">
              <div className="header">
                <label>Grantor</label>
              </div>
              <div
                style={{
                  marginTop: "15px",
                  overflow: "auto",
                  maxHeight: "39px",
                }}
              >
                <TagsInput
                  value={filterData.grantors}
                  disabled={true}
                  inputProps={{ placeholder: "Grantor" }}
                />
              </div>
            </div>
            <div className="grantees">
              <div className="header">
                <label>Grantee</label>
              </div>
              <div
                style={{
                  marginTop: "15px",
                  overflow: "auto",
                  maxHeight: "39px",
                }}
              >
                <TagsInput
                  disabled={true}
                  value={filterData.grantees}
                  inputProps={{ placeholder: "Grantee" }}
                />
              </div>
            </div>
          </>
        )}
        {!filterData.isGGSeparate && (
          <div className="ggTogether">
            <div className="header">
              <label>Name</label>
            </div>
            <div
              style={{ marginTop: "15px", overflow: "auto", maxHeight: "70px" }}
            >
              <TagsInput
                disabled={true}
                value={filterData.ggTogether}
                inputProps={{ placeholder: "Name" }}
              />
            </div>
          </div>
        )}
      </div>
      <div className="d-flex" >
         <div className="dates">
          <div className="header">
            <label>Recorded Date</label>
          </div>
          <div className="d-flex date">
            <div>
              <label>From</label>
              <input
                type="date"
                name="from_date"
                readOnly={true}
                value={filterData.from_date}
                className="form-control bg-transparent date-filter"
              />
            </div>
            <div className="ml-2">
              <label>To</label>
              <input
                type="date"
                name="to_date"
                readOnly={true}
                value={filterData.to_date}
                className="form-control bg-transparent date-filter"
              />
            </div>
          </div>
         </div>
         <div className="county">
          <div className="header">
            <label>County</label>
          </div>
          <div className="county-filter">
            <SelectViewNew
              className="filter"
              options={filterData.county.map((item) => ({
                value: item,
                label: item,
              }))}
              values={filterData.county.map((item) => ({
                value: item,
                label: item,
              }))}
              placeholder="County"
              multiple
              disabled={true}
              width={300}
              theme={theme}
            />
          </div>
         </div>
      </div>
      <div className="d-flex" style={{ zIndex: 10 }}>
        <div className="check-box separateGG">
          <input
            className="check-pointer"
            type="checkbox"
            name="isGGSeparate"
            readOnly={true}
            checked={filterData.isGGSeparate}
          />
          <label>Search by Grantor/Grantee</label>
        </div>
        <div className="check-box separateGG check-box-date" style={{ marginLeft: "228px" }}>
          <input
            className="check-pointer"
            type="checkbox"
            name="isDateFiltered"
            readOnly={true}
            checked={filterData.isDateFiltered}
          />
          <label>Search all Dates</label>
        </div>
      </div>
      <div className="d-flex" >
        
        <div className="instrument-type">
          <div className="header">
            <label>Instrument Type</label>
          </div>
          <div>
            <SelectViewNew
              className="filter"
              options={filterData.instrument_type.map((item) => ({
                value: item,
                label: item,
              }))}
              values={filterData.instrument_type.map((item) => ({
                value: item,
                label: item,
              }))}
              placeholder="Instrument Type"
              multiple
              disabled={true}
              width={300}
              theme={theme}
            />
          </div>
        </div>
        <div className="input-fields">
          <div className="header">
            <label>Book</label>
          </div>
          <div>
            <input
              placeholder="Book"
              className="form-control bg-transparent"
              type="text"
              name="book"
              autoComplete="off"
              readOnly={true}
              value={filterData.book}
            />
          </div>
        </div>
        <div className="input-fields">
          <div className="header">
            <label>Page</label>
          </div>
          <div>
            <input
              placeholder="Page"
              name="page"
              className="form-control bg-transparent"
              type="text"
              readOnly={true}
              autoComplete="off"
              value={filterData.page}
            />
          </div>
        </div>
      </div>
      <div className="d-flex">
        
        <div className="trsm">
          <div className="header">
            <label>TRS</label>
          </div>
          <div
          className="trsm-body"
            style={{
              marginTop: "15px",
              maxWidth: "785px",
              maxHeight: "50px",
              overflow: "auto",
            }}
          >
            <TagsInput
              value={filterData.trsm ?? []}
              disabled={true}
              inputProps={{ placeholder: "01N-01W-01" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  theme: state.user.theme,
})

export default connect(mapStateToProps)(SearchFilterView);
