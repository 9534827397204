import { AgGridReact } from "ag-grid-react";
import { connect } from "react-redux";
import React, { useRef, useEffect, useCallback, useState } from 'react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import { useRowContext } from "../../../contexts/rowContext";

function Table({ cols, data, theme, gridFilterTrigger, columnDef = {}, height = 50, loading, tableId }) {
  const [colDef, setColDef] = useState(cols);
  // const defaultHeight = 50;
  const gridRef = useRef();
  const { expandedRowIndex } = useRowContext();
  const defCols = {
    resizable: true,
    sortable: true,
    filter: true,
    minWidth: 100,
    suppressMovable: true,
    ...columnDef,
  };

  const LoadingSpinner = () => (
    <div className="loader">
      <div className="outer"></div>
      <div className="middle"></div>
      <div className="inner"></div>
    </div>
  );

  const getRowHeight = (params) => {
    if (params.data.expand && params.data.height) {
      return params.data.height;
    }
    return height;
  };

  const handleRowHeightChange = (rowIndex) => {
    if (gridRef.current) {
      const api = gridRef.current.api;
      if (rowIndex !== null && api) {
        api.ensureIndexVisible(rowIndex, 'nearest');
      }
    }
  };

  useEffect(() => {
    if (expandedRowIndex !== null) {
      handleRowHeightChange(expandedRowIndex);
    }
  }, [data, expandedRowIndex]);

  const calculateColumnWidth = useCallback((width, colId) => {
    const fontSize = 13; // px
    const avgCharWidth = fontSize * 0.65; // Adjust as necessary
    const padding = 8 + 8;
    const stringLength = Math.floor((width - padding)/avgCharWidth);
    const col = cols.find(col => col.field === colId);
    if(col && stringLength > 0) {
      const columns = cols.map(col => {
        if(col.field === colId) {
          col.cellRendererParams.strLength = stringLength;
        }
        return col;
      })
      setColDef(columns);
    };
  }, [cols]);

  useEffect(() => {
    const clearFilter = () => {
      if (gridRef.current?.api) {
        gridRef.current.api.setFilterModel(null); // Clear all filters
      }
    }
    clearFilter();
  }, [gridFilterTrigger, gridRef]);

  useEffect(() => {
    if(gridRef.current) {
      const observeColumns = ["gr", "ge"]; // Columns to observe
      const resizeObservers = [];
      observeColumns.forEach(colId => {
        const headerElement = document.querySelector(`.ag-header-cell[col-id="${colId}"]`);
        if (headerElement) {
          const observer = new ResizeObserver((entries) => {
            for (let entry of entries) {
              const colId = entry.target.getAttribute('col-id');
              const width = entry.contentRect.width;
              calculateColumnWidth(width, colId);
            }
            observer.disconnect();
          });
          observer.observe(headerElement);
          resizeObservers.push(observer);
        }
      });
      return () => {
        resizeObservers.forEach((observer) => observer.disconnect());
      };
    }
  }, [gridRef, calculateColumnWidth]);

  const onColumnResized = useCallback((params) => {
    if (params.columnApi) {
      const allColumns = params.columnApi.getColumns();
      const columnWidths = allColumns.map((col) => ({
        colId: col.getColId(),
        width: col.getActualWidth(),
      }));
      localStorage.setItem(`columnWidths_${tableId}`, JSON.stringify(columnWidths));
    }
  }, [tableId]);

  useEffect(() => {
    // Load column widths from local storage using the unique key
    const savedColumnWidths = JSON.parse(localStorage.getItem(`columnWidths_${tableId}`));
    if (savedColumnWidths) {
      const updatedCols = cols.map((col) => {
        const savedWidth = savedColumnWidths.find(
          (saved) => saved.colId === col.field
        );
        if (savedWidth) {
          col.width = savedWidth.width;
        }
        return col;
      });
      setColDef(updatedCols);
    }
  }, [cols, tableId]);

  return (
    <div className={`table ${theme === "dark" ? "dark" : ""} d-flex`}>
      <div className="grid ag-theme-material">
        {loading ? (
          <div className="loading-spinner-wrapper"><LoadingSpinner /></div>
        ) : (
          <AgGridReact
            ref={gridRef}
            rowData={data}
            columnDefs={colDef}
            defaultColDef={defCols}
            rowSelection="multiple"
            suppressRowClickSelection={true}
            suppressScrollOnNewData={true}
            getRowHeight={getRowHeight}
            onColumnResized={onColumnResized}
            gridOptions={{
              enableCellTextSelection: true,
            }}
          />
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  theme: state.user.theme,
  gridFilterTrigger: state.user.filterTrigger,
});

const TableContainer = connect(mapStateToProps, null)(Table);

export default React.memo(TableContainer);