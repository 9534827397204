import { useEffect, useState } from "react";
import { connect } from "react-redux";

import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import NavBarContainer from "../../components/common/navbar";
import Table from "../../components/common/table";
import Modal from "../../components/common/modal";
import SearchFilterView from "../../components/searchFilter/viewFilter";
import SelectViewNew from "../../components/searchFilter/SelectViewNew";
import { toast } from "react-toastify";
import { getUserLogList, getUsersList } from "../../actions/userLogAction";
import "./userLog.scss";
import MyBarChart from "../../components/common/chart";
import PdfModal from "../../components/common/pdfModal";
import { getInstrumentPdf } from "../../actions/advancedSearchAction";
import { WithContext as ReactTags } from "react-tag-input";

function UserLog({ userLogList, users, defaultFilters, theme, getUserLogList, getUsersList, loading, selectedPdf, getInstrumentPdf }) {
	const [filters, setFilters] = useState(defaultFilters);
	const inputsHandler = (e) => {
		setFilters({ ...filters, [e.target.name]: e.target.value });
	};
	const [showModal, setShowModal] = useState(false);
	const [eventData, setEventData] = useState({});
	const [showPdf, setShowPdf] = useState(false);

	useEffect(() => {
		getUsersList();
		getUserLogList(defaultFilters);
	}, [getUsersList, getUserLogList, defaultFilters]);

	const showFilterData = (data) => {
		try {
			const parsedData = JSON.parse(data.EventData);
			const filterData = {
				...parsedData,
				county: parsedData.county === '' ? [] : parsedData.county.split(","),
				instrument_type: parsedData.instrument_type === '' ? [] : parsedData.instrument_type.split(","),
				grantees: parsedData.grantees === '' ? [] : parsedData.grantees.split(","),
				grantors: parsedData.grantors === '' ? [] : parsedData.grantors.split(","),
				isGGSeparate: parsedData.gg_condition === 'AND',
				isDateFiltered: parsedData.isDateFiltered ?? false,
				trsm: parsedData.trs === '' ? [] :  parsedData.trs.split(","),
			};
			filterData['ggTogether'] = parsedData.gg_condition === 'AND' ? [] : filterData.grantees;
			setEventData(filterData);
			setShowModal(true);
		} catch(e) {
			toast.error('Invalid filter json');
		}
	}

	const cols = [
		{
			headerName: "Username",
			field: "UserName",
			flex: 1,
			wrapHeaderText: true,
		},
		{
			headerName: "IP Address",
			field: "IPAddress",
			flex: 1,
			wrapHeaderText: true,
		},
		{
			headerName: "Date Time",
			field: "DateTimeEvent",
			wrapHeaderText: true,
			flex: 1,
			filter: "agDateColumnFilter",
			cellRenderer: ({ value }) =>
				value instanceof Date
					? value.toLocaleString("en-US", { timeZone: "UTC" })
					: "",
		},
		{
			headerName: "Event Type",
			field: "EventType",
			flex: 1,
			wrapHeaderText: true,
		},
        {
          headerName: "Event Data",
          resizable: false,
          filter: false,
          minWidth: 120,
          maxWidth: 120,
          wrapHeaderText: true,
          cellRenderer: ({ data }) => {
            const handleIconClick = () => {
              if (
                data.EventType === "Viewed Instruments" ||
                data.EventType === "Downloaded Instruments"
              ) {
                const [co, iidWithExt] = data.EventData.split("_");
                const iid = iidWithExt.replace(".pdf", "");
         
                const requestRecord = {
                  co: co,
                  iid: iid,
                };
                getInstrumentPdf(requestRecord);
                setShowPdf(true);
              } else if (data.EventType === "Search") {
                showFilterData(data);
              }
            };
            return (
              ["Viewed Instruments", "Downloaded Instruments", "Search"].includes(
                data.EventType
              ) && (
                <MdOutlineRemoveRedEye
                  className={`btn-icon ${theme === "dark" ? "btn-light" : ""}`}
                  onClick={handleIconClick}
                />
              )
            );
          },
        },
    ];
	
	const evenTypeHandler = (e) => {
		setFilters({
			...filters,
			eventtype: e.join(','),
			eventType: [...e]
		});
	}

	const usernameHandler = (e) => {
		setFilters({
			...filters,
			username: e.join(','),
			userName: [...e]
		});
	}
	return (
    <div
      className={`dashboard${
        theme === "dark" ? "-dark" : ""
      } d-flex flex-column`}
    >
      <NavBarContainer />
      <div className="content d-flex flex-column">
        <div className="search mb-1">
          <div className="d-flex">
            <div className="mt--5">
              <label>From</label>
              <input
			  style={{ border: "1px solid #ced4da", height: '38px'}}
                type="date"
                name="fromdate"
                value={filters.fromdate}
                onChange={inputsHandler}
                className="form-control bg-transparent date-filter input-calender"
              />
            </div>
            <div className="ml-3 mt--5">
              <label>To</label>
              <input
			  style={{ border: "1px solid #ced4da", height: '38px'}}
                type="date"
                name="todate"
                value={filters.todate}
                onChange={inputsHandler}
                className="form-control bg-transparent date-filter"
              />
            </div>
            <div className="ml-3 mt--5">
              <label>Username</label>
			  <SelectViewNew
              className="filter"
              options={[{username: 'All'}, ...users].map((item) => ({ value: item.username, label: item.username }))}
              values={filters.userName.map((item) => ({
                value: item,
                label: item,
              }))}
              placeholder="Username"
              searchable
              onChange={usernameHandler}
			  theme = {theme}
              width={300}
            />
            </div>
            <div className="ml-3 mt--5">
              <label>Event Type</label>
              <SelectViewNew
              className="filter"
              options={['All','Login', 'Logout', 'Search', 'OKCR Integration'].map((item) => ({ value: item, label: item }))}
              values={filters.eventType.map((item) => ({
                value: item,
                label: item,
              }))}
              placeholder="Event Type"
              searchable
              onChange={evenTypeHandler}
			  theme = {theme}
              width={300}
            />
            </div>
          </div>
          <div className="user-btn mt-2">
          <button
			className="btn btn-info mr-3"
			onClick={() => {
			  setFilters(defaultFilters);
			}}
			>
				Clear All
			</button>
            <button
              className="btn btn-primary"
              onClick={() => {
                getUserLogList(filters);
              }}
            >
              Apply
            </button>
          </div>
        </div>
        <div className="data d-flex flex-column">
          <Tabs defaultActiveKey="userLog">
            <Tab eventKey="userLog" title="User Log">
            { loading && <span className="ml-2"> Fetching Data. Please Wait... </span>}
              <div className="digital d-flex flex-column">
                <Table cols={cols} data={userLogList ?? []} />
              </div>
            </Tab>
            <Tab eventKey="userInsights" title="User Insights">
			{/* { loading && <span className="ml-2"> Fetching Data. Please Wait... </span>} */}
              <div className="digital d-flex flex-column" style={{height:"100%", overflowY:"auto"}}>
              <MyBarChart data={userLogList ?? []} theme={theme}/>
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
      <Modal
	  	showModal={showModal}
		contentClass='filterContent filter-userLog'
		dialogClass='filterDialog'
		header={""}
		theme = {theme}
		html={<SearchFilterView filterData={eventData}/>}
        footerHtml={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div
              style={{
                width: "550px",
                whiteSpace: "normal",
                right: "10px",
                marginTop: "15px",
              }}
            >
              <ReactTags
               tags={Object.entries(eventData)
                 .filter(
                   ([key, value]) =>
                     key !== "gg_condition" &&
                     key !== "gg_together" &&
                     key !== "isDateFiltered" &&
                     key !== "trs_array" &&
                     key !== "trsm" &&
                     key !== "isGGSeparate" &&
                     value !== null &&
                     value !== undefined &&
                     value !== "" &&
                     !(Array.isArray(value) && value.length === 0) &&
                     !(
                       typeof value === "object" &&
                       Object.keys(value).length === 0
                     )
                 )
                 .flatMap(([key, value]) => {
                   if (
                     ["grantors", "grantees", "ggTogether"].includes(key) &&
                     Array.isArray(eventData.grantors) &&
                     Array.isArray(eventData.grantees) &&
                     Array.isArray(eventData.ggTogether) &&
                     JSON.stringify(eventData.grantors) === JSON.stringify(eventData.grantees) &&
                     JSON.stringify(eventData.grantees) === JSON.stringify(eventData.ggTogether)
                   ) {
                     if (key !== "grantors") {
                       return [];
                     }
                   }
                   if (key === "trs" && typeof value === "string") {
                     const tags = value.split(",").map((chunk) => chunk.trim());
                     return tags.map((chunk) => ({ id: chunk, text: chunk }));
                   }
                   if (key === "county" && Array.isArray(value)) {
                     return value.map((county) => ({
                       id: county,
                       text: county,
                     }));
                   }
                   if (Array.isArray(value)) {
                     return value.map((item) => ({ id: item, text: item }));
                   }
                   return [{ id: value, text: value }];
                 })}
               allowDragDrop={false}
               readOnly={true}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <button
                className="btn btn-info"
                onClick={() => {
                  const advancedSearchUrl = `/?eventData=${encodeURIComponent(
                    JSON.stringify(eventData)
                  )}`;
                  window.open(advancedSearchUrl, "_blank");
                }}
              >
                Open in New Window
              </button>
              <button
                className="btn btn-info"
                style={{ marginLeft: "5px" }}
                onClick={() => setShowModal(false)}
              >
                Close
              </button>
            </div>
          </div>
        }
        onHide={() => setShowModal(false)}
      ></Modal>
      <PdfModal
        selectedPdf={selectedPdf}
        showModal={showPdf}
        onHide={() => setShowPdf(false)}
      ></PdfModal>
    </div>
  );
}

const mapStateToProps = (state) => {
	return {
		userLogList: state.userLog.userLogList,
		users: state.userLog.usersList,
		loading: state.userLog.loading,
		defaultFilters: state.userLog.defaultFilters,
		theme: state.user.theme,
		selectedPdf: state.advancedSearch.selectedPdf,
	};
};

const mapDispatchToProps = (dispatch) => ({
	getUserLogList: (filters) => dispatch(getUserLogList(filters)),
	getUsersList: () => dispatch(getUsersList()),
	getInstrumentPdf: (instrument) => dispatch(getInstrumentPdf(instrument)),
});

const UserLogContainer = connect(mapStateToProps, mapDispatchToProps)(UserLog);

export default UserLogContainer;
